import React from "react";
import UserAppBar from "../Components/UserAppBar";

export default function Home() {
  return (
    <div>
      <UserAppBar />
      <h1 className="text-center">Home</h1>
    </div>
  );
}