import * as React from 'react';
import { Paper, Table, TableBody, TableCell, TableContainer, TableHead, TableRow } from "@mui/material";
import { stripeDataPoints } from '../constants';


const StripeRevTable = ({data}) => {
    console.log('papapapapa', data)
  return (
    <TableContainer component={Paper}>
      <Table aria-label="simple table">
        <TableHead>
          <TableRow>
            <TableCell>Stripe</TableCell>
            <TableCell align="right">Revenue</TableCell>
            <TableCell align="right">New Subs</TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
           <TableRow
              key={'adv-monthly'}
              sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
            >
              <TableCell component="th" scope="row">
                Adv | Monthly
              </TableCell>
              <TableCell align="right">${data.advMonthRev || 0}</TableCell>
              <TableCell align="right">{data.advMonthSubs || 0}</TableCell>
            </TableRow>
            <TableRow
              key={'adv-6mo'}
              sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
            >
              <TableCell component="th" scope="row">
                Adv | 6-Mo
              </TableCell>
              <TableCell align="right">${data.advHalfYearRev || 0}</TableCell>
              <TableCell align="right">{data.advHalfYearSubs || 0}</TableCell>
            </TableRow>
            <TableRow
              key={'adv-1yr'}
              sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
            >
              <TableCell component="th" scope="row">
                Adv | 1-year
              </TableCell>
              <TableCell align="right">${data.advYearRev || 0}</TableCell>
              <TableCell align="right">{data.advYearSubs || 0}</TableCell>
            </TableRow>
            <TableRow
              key={'jts-1m'}
              sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
            >
              <TableCell component="th" scope="row">
                JTS | Monthly
              </TableCell>
              <TableCell align="right">${data.jtsMonthRev || 0}</TableCell>
              <TableCell align="right">{data.jtsMonthSubs || 0}</TableCell>
            </TableRow>
            <TableRow
              key={'jts-6mo'}
              sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
            >
              <TableCell component="th" scope="row">
                JTS | 6-Mo
              </TableCell>
              <TableCell align="right">${data.jtsHalfYearRev || 0}</TableCell>
              <TableCell align="right">{data.jtsHalfYearSubs || 0}</TableCell>
            </TableRow>
            <TableRow
              key={'jts-1y'}
              sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
            >
              <TableCell component="th" scope="row">
                JTS | 1-Yr
              </TableCell>
              <TableCell align="right">${data.jtsYearRev || 0}</TableCell>
              <TableCell align="right">{data.jtsYearSubs || 0}</TableCell>
            </TableRow>
        </TableBody>
      </Table>
    </TableContainer>
  );
}

export default StripeRevTable;