import { Alert, Autocomplete, Backdrop, Box, Button, Checkbox, CircularProgress, Collapse, Divider, Grid, IconButton, Paper, Table, TableBody, TableCell, TableContainer, TableHead, TablePagination, TableRow, TableSortLabel, Tooltip, tooltipClasses, Typography, withStyles } from "@mui/material";
import { Container } from "@mui/system";
import TextField from '@mui/material/TextField';
import { DataGrid } from '@mui/x-data-grid';
import React, { useEffect, useMemo, useState } from "react";
import UserAppBar from "../Components/UserAppBar";
import axios from "axios";
import { styled } from '@mui/material/styles';
import { MobileDatePicker } from '@mui/x-date-pickers/MobileDatePicker';
import { BASE_URL } from "../constants";
import APIKit from "../services/api";
import KeyboardDoubleArrowUpIcon from '@mui/icons-material/KeyboardDoubleArrowUp';
import KeyboardDoubleArrowDownIcon from '@mui/icons-material/KeyboardDoubleArrowDown';
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';
import KeyboardArrowUpIcon from '@mui/icons-material/KeyboardArrowUp';
import InfoIcon from '@mui/icons-material/InfoOutlined';
import { Sparklines, SparklinesLine, SparklinesReferenceLine } from 'react-sparklines';
import KeyboardDoubleArrowUp from "@mui/icons-material/KeyboardDoubleArrowUp";
import { visuallyHidden } from '@mui/utils';
import KeyboardArrowUp from "@mui/icons-material/KeyboardArrowUp";
import KeyboardArrowDown from "@mui/icons-material/KeyboardArrowDown";
const adCountComputor = (row) => {
  const adsMovedToDoneCount = row.adMovedToDoneUrl ? row.adMovedToDoneUrl.split(', ').length : 0;//row.adMovedToDoneUrl ? row.adMovedToDoneUrl.split(', ').length : 0;
  const newAdsCount = row.newAdUrl ? row.newAdUrl.split(', ').length : 0;
  return {
    ...row,
    adsMovedToDoneCount,
    newAdsCount
  }
}
// const HtmlTooltip = styled(({ className, ...props }) => (
//   <Tooltip {...props} classes={{ popper: className }} />
// ))(({ theme }) => ({
//   [`& .${tooltipClasses.tooltip}`]: {
//     backgroundColor: '#f5f5f9',
//     color: 'rgba(0, 0, 0, 0.87)',
//     maxWidth: 220,
//     fontSize: theme.typography.pxToRem(12),
//     border: '1px solid #dadde9',
//   },
// }));
function descendingComparator(a, b, orderBy) {
  if (b[orderBy] < a[orderBy]) {
    return -1;
  }
  if (b[orderBy] > a[orderBy]) {
    return 1;
  }
  return 0;
}

function getComparator(order, orderBy) {
  return order === 'desc'
    ? (a, b) => descendingComparator(a, b, orderBy)
    : (a, b) => -descendingComparator(a, b, orderBy);
}
// const columns = [
//   { field: 'id', headerName: 'ID', width: 70 },
//   { field: 'yyyymmdd', headerName: 'Date', width: 100 },
//   { field: 'accountsFollowed', type: 'number',headerName: 'Followed', width: 70 },
//   { field: 'messagesReceived', type: 'number', headerName: 'Received', width: 70 },
//   { field: 'messagesSent',type: 'number', headerName: 'Sent', width: 70 },
//   { field: 'username', headerName: 'handle', width: 200},
//   { field: 'workerUsername', headerName: 'Worker', width: 70 },
//   { field: 'adMovedToDoneUrl', headerName: 'Done Ads', width: 70, valueGetter: (params) => {
//     if(!params.row.adMovedToDoneUrl) {
//       return 0;
//     }
//     return params.row.adMovedToDoneUrl.split(', ').length;
//   }},
//   { field: 'newAdUrl', type: 'number', headerName: 'New Ads', width: 70, valueGetter: (params) => {
//     if(!params.row.newAdUrl) {
//       return 0;
//     }
//     return params.row.newAdUrl.split(', ').length;
//   }},
// ];
const headCells = [
  {
    id: 'id',
    numeric: false,
    // disablePadding: true,
    label: 'Id',
  },
  {
    id: 'yyyymmdd',
    numeric: true,
    // disablePadding: false,
    label: 'Date',
  },
  {
    id: 'accountsFollowed',
    numeric: true,
    // disablePadding: false,
    label: 'Acc followed',
  },
  {
    id: 'messagesReceived',
    numeric: true,
    // disablePadding: false,
    label: 'Msg Received',
  },
  {
    id: 'engagementsPerClick',
    numeric: true,
    // disablePadding: false,
    label: 'Engagements',
  },
  {
    id: 'minutesSpent',
    numeric: true,
    // disablePadding: false,
    label: 'Minutes Spent',
  },
  {
    id: 'amountSpent',
    numeric: true,
    // disablePadding: false,
    label: 'Amount Spent',
  },
  {
    id: 'messagesSent',
    numeric: true,
    // disablePadding: false,
    label: 'Msg Sent',
  },
  {
    id: 'username',
    // numeric: true,
    // disablePadding: false,
    label: 'Username',
  },
  {
    id: 'workerUsername',
    // numeric: true,
    disablePadding: false,
    label: 'Worker',
  },
  {
    id: 'adsMovedToDoneCount',
    numeric: true,
    // disablePadding: false,
    label: 'Ads Done',
  },
  {
    id: 'newAdsCount',
    numeric: true,
    // disablePadding: false,
    label: 'New Ads',
  },
];
const EnhancedTableHead = (props) => {
  const { onSelectAllClick, order, orderBy, numSelected, rowCount, onRequestSort } =
    props;
  const createSortHandler = (property) => (event) => {
    onRequestSort(event, property);
  };

  return (
    <TableHead>
      <TableRow>
        {/* <TableCell padding="checkbox"> */}
          {/* <Checkbox
            color="primary"
            indeterminate={numSelected > 0 && numSelected < rowCount}
            checked={rowCount > 0 && numSelected === rowCount}
            onChange={onSelectAllClick}
            inputProps={{
              'aria-label': 'select all desserts',
            }}
          />
        </TableCell> */}
        {headCells.map((headCell) => (
          <TableCell
            key={headCell.id}
            // align={headCell.numeric ? 'right' : 'left'}
            align={'left'}
            padding={headCell.disablePadding ? 'none' : 'normal'}
            sortDirection={orderBy === headCell.id ? order : false}
          >
            <TableSortLabel
              active={orderBy === headCell.id}
              direction={orderBy === headCell.id ? order : 'asc'}
              onClick={createSortHandler(headCell.id)}
            >
              {headCell.label}
              {orderBy === headCell.id ? (
                <Box component="span" sx={visuallyHidden}>
                  {order === 'desc' ? 'sorted descending' : 'sorted ascending'}
                </Box>
              ) : null}
            </TableSortLabel>
          </TableCell>
        ))}
      </TableRow>
    </TableHead>
  );
}

const Row = (props) => {
  const { row } = props;
  const adsMovedToDoneCount = row.adsMovedToDoneCount || 0;//row.adMovedToDoneUrl ? row.adMovedToDoneUrl.split(', ').length : 0;
  const newAdsCount = row.newAdsCount || 0;
  const needCollapsible = !!(adsMovedToDoneCount || newAdsCount);
  const [open, setOpen] = React.useState(false);
  return (
    <React.Fragment>
      <TableRow sx={{ '& > *': { borderBottom: 'unset' } }}>
        <TableCell>
          {row.id}
          {needCollapsible && <IconButton
            aria-label="expand row"
            size="small"
            onClick={() => setOpen(!open)}
          >
            {open ? <KeyboardArrowUpIcon /> : <KeyboardArrowDownIcon />}
          </IconButton>}
        </TableCell>
        {/* <TableCell align="left">{row.id}</TableCell> */}
        <TableCell align="left">{row.yyyymmdd}</TableCell>
        <TableCell align="left">{row.accountsFollowed}</TableCell>
        <TableCell align="left">{row.messagesReceived}</TableCell>
        <TableCell align="left">{row.engagementsPerClick}</TableCell>
        <TableCell align="left">{row.minutesSpent}</TableCell>
        <TableCell align="left">{row.amountSpent}</TableCell>
        <TableCell align="left">{row.messagesSent}</TableCell>
        <TableCell align="left"><a href={row.link || `https://instagram.com/${row.username}`}>{row.username}</a></TableCell>
        <TableCell align="left">{row.workerUsername}</TableCell>
        <TableCell align="left">{adsMovedToDoneCount}</TableCell>
        <TableCell align="left">{newAdsCount}</TableCell>
      </TableRow>
      {!!(adsMovedToDoneCount || newAdsCount) && <TableRow>
        <TableCell style={{ paddingBottom: 0, paddingTop: 0 }} colSpan={100}>
          <Collapse in={open} timeout="auto" unmountOnExit>
            <Box sx={{ margin: 1 }}>
              <Typography variant="h6" gutterBottom component="div">
                Ads
              </Typography>
              <Table size="small" aria-label="purchases">
                <TableHead>
                  <TableRow>
                    <TableCell>Type</TableCell>
                    <TableCell>Url</TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  {!!row.newAdUrl && row.newAdUrl.split(', ').map((newAdRow, j) => (
                    <TableRow key={`new-${j}`}>
                      <TableCell component="th" scope="row">
                        New
                      </TableCell>
                      <TableCell component="th" scope="row" width="10%">
                        {newAdRow}
                      </TableCell>
                    </TableRow>
                  ))}
                   {!!row.adMovedToDoneUrl && row.adMovedToDoneUrl.split(', ').map((adMovedToDoneRow, j) => (
                    <TableRow key={`old-${j}`}>
                      <TableCell component="th" scope="row">
                        Done
                      </TableCell>
                      <TableCell component="th" scope="row" width="10%">
                        {adMovedToDoneRow}
                      </TableCell>
                    </TableRow>
                  ))}
                </TableBody>
              </Table>
            </Box>
          </Collapse>
        </TableCell>
      </TableRow>}
    </React.Fragment>
  );
}
export default function MASubmissionsListing() {
  const [accounts, setAccounts] = useState([]);
  const [maWorkers, setMaWorkers] = useState([]);
  const [submissions, setSubmissions] = useState([]);
  const [order, setOrder] = React.useState('asc');
  const [orderBy, setOrderBy] = React.useState('calories');
  const [loading, setLoading] = useState(false);
  const [filterDetails, setFilterDetails] = useState({});
  const [page, setPage] = useState(0)
  const [rowsPerPage, setRowsPerPage] = useState(10);
  const [metrics, setMetrics] = useState({
    basic: [],
    ma: [],
    meta: {}
  });
   const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };
  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };
  const handleRequestSort = (event, property) => {
    const isAsc = orderBy === property && order === 'asc';
    setOrder(isAsc ? 'desc' : 'asc');
    setOrderBy(property);
  };
  const fetchAllAccounts = async () => {
    try {
      const { data } = await APIKit.get(`${BASE_URL}/api/accounts`)
      console.log('ako data ', data);
      if (data && data.accounts) {
        setAccounts(data.accounts.map(s => s.username))
      }
    } catch (e) {
      console.log('fetch error', e);
    }
  };
  const fetchAllMAWorkers = async () => {
    try {
      const { data } = await APIKit.get(`${BASE_URL}/api/maWorkers`)
      console.log('ako workers ', data);
      if (data && data.users) {
        setMaWorkers(data.users.map(s => ({label: s.username, id: s.id})))
      }
    } catch (e) {
      console.log('fetch error', e);
    }
  };
  const fetchMaSubmissions = async () => {
    setLoading(true);
    try {
      const { data } = await APIKit.post(`${BASE_URL}/api/getMaSubmissions`, filterDetails)
      console.log('submissions are', data);
      if(data && data.submissions) {
        setSubmissions(data.submissions.map(s => adCountComputor(s)))
      }
    } catch (e) {
      console.log('fetch error', e);
    }
    setPage(0);
    setLoading(false);
  }
  console.log('total=====', submissions.length)
  useEffect(() => {
    fetchMaSubmissions()
    fetchAllAccounts()
    fetchAllMAWorkers()
  }, [])
  useEffect(() => {
    fetchMaSubmissions();
  }, [filterDetails])

  return (
    <div>
      <UserAppBar />
      <Container maxWidth={false}>
        <Box
          style={{ paddingTop: 20 }}
          sx={{
            display: 'flex',
            flexWrap: 'wrap',
            p: 1,
            m: 1,
            gap: 2,
            justifyContent: 'space-around'
          }}
        >
          <Autocomplete
            disablePortal
            onChange={(s, val) => {
              console.log('changed', s.target.value, val)
              setFilterDetails(prev => {
                return {
                  ...prev,
                  platform: 'instagram',
                  username: val,
                }
              })
            }}
            id="combo-box-demo"
            options={accounts}
            sx={{ width: 300 }}
            renderInput={(params) => <TextField {...params} label="Handle" />}
          />
          <Autocomplete
            disablePortal
            onChange={(s, val) => {
              setFilterDetails(prev => {
                return {
                  ...prev,
                  platform: 'instagram',
                  // username: val,
                  worker: val && val.id,
                }
              })
            }}
            id="combo-box-demo"
            options={maWorkers}
            sx={{ width: 300 }}
            renderInput={(params) => <TextField {...params} label="MA Worker" />}
          />
          <MobileDatePicker
            closeOnSelect
            onAccept={(val) => {
              setFilterDetails(prev => {
                return {
                  ...prev,
                  platform: 'instagram',
                  // username: val,
                  startDate: val.toISOString(),
                }
              })
            }}
            label="Start date"
            value={filterDetails.startDate || new Date()}
            inputFormat="MM/DD/YYYY"
            onChange={(val) => {
              // setFilterDetails(prev => {
              //   return {
              //     ...prev,
              //     platform: 'instagram',
              //     // username: val,
              //     startDate: val.toISOString(),
              //   }
              // })
            }}
            renderInput={(params) => <TextField {...params} />}
          />
          <MobileDatePicker
            label="End date"
            inputFormat="MM/DD/YYYY"
            value={filterDetails.endDate || new Date()}
            // onChange={handleChange}
            onChange={(val) => {
              setFilterDetails(prev => {
                return {
                  ...prev,
                  platform: 'instagram',
                  // username: val,
                  endDate: val.toISOString(),
                }
              })
            }}
            renderInput={(params) => <TextField {...params} />}
          />
        </Box>
        <Paper elevation={3} sx={{height: 600}}>
          <Box sx={{ p: 1 }}>
            <Typography component="h1" variant="h5" textAlign="left">Info {metrics.meta && metrics.meta.progressStartDate ? `(${metrics.meta.progressStartDate} to ${metrics.meta.progressEndDate})` : ''}</Typography>
          </Box>
            <TableContainer component={Paper}>
      <Table aria-label="collapsible table">
        {/* <TableHead>
          <TableRow>
            <TableCell>Id</TableCell>
            <TableCell>Date</TableCell>
            <TableCell align="left">Followed</TableCell>
            <TableCell align="left">Received</TableCell>
            <TableCell align="left">Sent</TableCell>
            <TableCell align="left">Handle</TableCell>
            <TableCell align="left">Worker</TableCell>
            <TableCell align="left">Done Ads</TableCell>
            <TableCell align="left">New Ads</TableCell>
          </TableRow>
        </TableHead> */}
        <EnhancedTableHead
              numSelected={0}
              order={order}
              orderBy={orderBy}
              onSelectAllClick={() => {}}
              onRequestSort={handleRequestSort}
              rowCount={submissions.length}
            />
        <TableBody>
          {submissions.sort(getComparator(order, orderBy))
                .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage).map((row) => (
            <Row key={row.id} row={row} />
          ))}
        </TableBody>
      </Table>
    </TableContainer>
    <TablePagination
          rowsPerPageOptions={[5, 10, 25, 100, 500, 1000]}
          component="div"
          count={submissions.length}
          rowsPerPage={rowsPerPage}
          page={page}
          onPageChange={handleChangePage}
          onRowsPerPageChange={handleChangeRowsPerPage}
        />
        </Paper>
      </Container>
      <Backdrop
        sx={{ color: '#fff', zIndex: (theme) => theme.zIndex.drawer + 1 }}
        open={loading}
        // onClick={handleClose}
      >
        <CircularProgress color="inherit" />
      </Backdrop>
    </div>
  );
}