import React, { useEffect, useContext, useState } from 'react';

const AuthContext = React.createContext();

export const useAppContext = () => {
  return useContext(AuthContext);
};

export const AuthContextProvider = props => {
  const [user, setUser] = useState();
  const _setUser = (_user) => {
    try {
      window.localStorage.setItem("sometracker-user-v2",JSON.stringify(_user));
      window.localStorage.setItem("sometracker-temp-token", _user.jwt);
      setUser(_user)
    } catch(e) {
      console.log('Set user error', e);
      window.localStorage.setItem("sometracker-user-v2", null);
      setUser(null)
    }
  }
  const logout = () => {
    try {
      window.localStorage.setItem("sometracker-user-v2",null);
      setUser(null)
    } catch(e) {
      console.log('Set user error', e);
      window.localStorage.setItem("sometracker-user-v2", null);
      setUser(null)
    }
  }
  useEffect(() => {
    const user = window.localStorage.getItem("sometracker-user-v2");
    if(user && user !== 'null') {
        setUser(JSON.parse(user));
    } else {
      setUser(null);
    }
  }, []);
  console.log('tyo====', typeof user)
  return (
    <AuthContext.Provider
      value={{
        user,
        setUser: _setUser,
        logout
      }}>
      {typeof user !== 'undefined' && props.children}
    </AuthContext.Provider>
  );
};

export default AuthContext;
