import logo from './logo.svg';
import CssBaseline from '@mui/material/CssBaseline';
import Container from '@mui/material/Container';
import Box from '@mui/material/Box';
import './App.css';
import React from 'react';
import { Routes, Route } from "react-router-dom";
import { Grid } from '@mui/material';
import Home from './Screens/Home';
import ProtectedRoutes from './Routes/ProtectedRoutes';
import Login from './Screens/Login';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider/LocalizationProvider';
import { AdapterMoment } from '@mui/x-date-pickers/AdapterMoment';
import { AuthContextProvider } from './Context/auth/AuthContext';
import AdminRoutes from './Routes/AdminRoutes';
import MarketingDashboard from './Screens/MarketingDashboard';
import MASubmissionsListing from './Screens/MASubmissionsListing';
import MASubmission from './Screens/MAWorkers/MASubmission';

function App() {
  return (
    <React.Fragment>
      <CssBaseline />
      <Container maxWidth={false} sx={{background: 'rgb(231, 235, 240)'}}>
        <AuthContextProvider>
        <LocalizationProvider dateAdapter={AdapterMoment}>
        <Grid container spacing={2}>
          <Grid item xs={12}>
          </Grid>
          <br/>
          <Grid item xs={12}>
            <Routes>
              <Route exact path="/" element={<ProtectedRoutes>
                <Home />
              </ProtectedRoutes>} />
              <Route exact path="/login" element={<Login />} />


              <Route path="/user/ma-submissions" element={<ProtectedRoutes>
                <MASubmission />
              </ProtectedRoutes>}/>




              <Route path="/admin/marketing-dashboard" element={<AdminRoutes>
                <MarketingDashboard />
              </AdminRoutes>}/>
              <Route path="/admin/ma-submissions-listing" element={<AdminRoutes>
                <MASubmissionsListing />
              </AdminRoutes>}/>
              {/* <Route path="/adhoc-scrape" element={<ProtectedRoutes>
                <AdhocScrape />
              </ProtectedRoutes>} />
              <Route path="/onDemandScraper/:id" element={<ProtectedRoutes>
                <OnDemandScrapeDisplay />
              </ProtectedRoutes>} /> */}
              <Route path="*" element={<p>There's nothing here: 404!</p>} />
            </Routes>
          </Grid>
        </Grid>
        </LocalizationProvider>
        </AuthContextProvider>
      </Container>
    </React.Fragment>
  );
}

export default App;
