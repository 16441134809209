// import { Alert, Autocomplete, Backdrop, Box, Button, Checkbox, CircularProgress, Collapse, Divider, Grid, IconButton, Paper, Table, TableBody, TableCell, TableContainer, TableHead, TablePagination, TableRow, TableSortLabel, Tooltip, tooltipClasses, Typography, withStyles } from "@mui/material";
// import { Container } from "@mui/system";
// import TextField from '@mui/material/TextField';
// import { DataGrid } from '@mui/x-data-grid';
// import React, { useEffect, useMemo, useState } from "react";
// import UserAppBar from "../Components/UserAppBar";
// import axios from "axios";
// import { styled } from '@mui/material/styles';
// import { MobileDatePicker } from '@mui/x-date-pickers/MobileDatePicker';
// import { BASE_URL } from "../constants";
// import APIKit from "../services/api";
// import KeyboardDoubleArrowUpIcon from '@mui/icons-material/KeyboardDoubleArrowUp';
// import KeyboardDoubleArrowDownIcon from '@mui/icons-material/KeyboardDoubleArrowDown';
// import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';
// import KeyboardArrowUpIcon from '@mui/icons-material/KeyboardArrowUp';
// import InfoIcon from '@mui/icons-material/InfoOutlined';
// import { Sparklines, SparklinesLine, SparklinesReferenceLine } from 'react-sparklines';
// import KeyboardDoubleArrowUp from "@mui/icons-material/KeyboardDoubleArrowUp";
// import { visuallyHidden } from '@mui/utils';
// import KeyboardArrowUp from "@mui/icons-material/KeyboardArrowUp";
// import KeyboardArrowDown from "@mui/icons-material/KeyboardArrowDown";

// export default function MASubmission() {
//   const [accounts, setAccounts] = useState([]);
//   const [loading, setLoading] = useState(false);
//   useEffect(() => {
//   }, [])

//   return (
//     <div>
//       <UserAppBar />
//       <Container maxWidth={false}>
//         <Paper elevation={3} sx={{height: 600}}>
//           <Box sx={{ p: 1 }}>
//             <Typography component="h1" variant="h5" textAlign="left">MAW Form</Typography>
//           </Box>
//         </Paper>
//       </Container>
//       <Backdrop
//         sx={{ color: '#fff', zIndex: (theme) => theme.zIndex.drawer + 1 }}
//         open={loading}
//       >
//         <CircularProgress color="inherit" />
//       </Backdrop>
//     </div>
//   );
// }
import React, { useEffect, useMemo, useState } from "react";
export default function() {
    return <>Coming soon</>;
}