import React, { useState } from "react";
import {
  Alert,
  Autocomplete,
  Box,
  Button,
  Checkbox,
  FormControlLabel,
  Modal,
  Paper,
  Snackbar,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  TextField,
  Typography,
} from "@mui/material";
import ImagePicker from "../common/ImagePicker";
import axios from "axios";
import useAuth from "../../Context/auth";
import { AP_URL } from "../../constants";
function PopAlert(props) {
  return <Alert variant="filled" {...props} />;
}
const UploadModal = ({ open, onClose, accounts }) => {
  const [imageUrl, setImageUrl] = useState(null);
  const [textValue, setTextValue] = useState("");
  const [usernames, setUsernames] = useState([]);
  const [sendtoAll, setSendToAll] = useState(false);
  const { user } = useAuth();
  const [loading, setLoading] = useState(false);
  const [snackBarMessage, setSnackBarMessage] = useState({
    severity: null,
    message: null
  });

  const handleFileChange = (newVal) => {
    setImageUrl(newVal);
  };
  const onSnackBarClose = () => {
    setSnackBarMessage({
      message: null
    });
  }
  const handleSave = async () => {
    try {
      if (!imageUrl || !textValue) {
        throw new Error("Missing Image or Caption");
      }
      setLoading(true);

      if(!window.confirm(`This will post to IG: ${sendtoAll ? 'all' : usernames.join(', ')} accounts. Are you sure ?`)) {
        throw new Error('Cancelled by user');
      }
      const { data } = await axios.post(
        `${AP_URL}/marketing/create_post`,
        {
          imageUrl,
          caption: textValue,
          usernames: sendtoAll ? 'all' : usernames,
        },
        {
          headers: {
            Authorization: `Basic ${user.APD_TOKEN}`,
          }
        }
      );
      console.log("Post response is", data);
      if(!data || !data.success) {
        throw new Error(`Error queuing post ${data?.error || 'Failed to post'}`);
      } else {
        setSnackBarMessage({
          message: "Success",
          severity: 'success'
        });
        setTimeout(() => {
          onClose()
        }, 5000)
        // onClose();
      }
      
    } catch (error) {
      console.log("Image upload error:", error);
      // setSnackBarMessage(error.toString());
      setSnackBarMessage({
          message: error.toString(),
          severity: 'error'
        });
    }
    setLoading(false);
  };
  return (
    <Modal
      open={open}
      onClose={onClose}
      style={{
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
      }}
    >
      <div style={{ backgroundColor: "#fff", padding: "1rem" }}>
        <Typography variant="h5">Create IG Post</Typography>
        <hr />
         <FormControlLabel
         sx={{ width: 700 }}
              control={<Checkbox value="remember" color="primary" onChange={(v) => {setSendToAll(v.target.checked)}}/>}
              label="Send to all IG accounts"
            />
        {!sendtoAll && <Autocomplete
            disablePortal
            onChange={(s, val) => {
              setUsernames(val);
            }}
            multiple
            id="combo-box-demo"
            options={accounts}
            sx={{ width: 700 }}
            renderInput={(params) => <TextField {...params} label="Username" />}
          />}
        <ImagePicker onChange={handleFileChange} />
        <TextField
          label="Enter some text"
          value={textValue}
          onChange={(e) => setTextValue(e.target.value)}
          multiline
          fullWidth
          margin="normal"
        />
        <Button variant="contained" onClick={handleSave}>
          Save
        </Button>
        <Snackbar
        color="success"
        open={!!snackBarMessage?.message}
        autoHideDuration={10000}
        onClose={onSnackBarClose}
        anchorOrigin={{ vertical: 'top', horizontal: 'center' }}
        // message={snackBarMessage}
      >
        <Alert severity={snackBarMessage.severity || 'info'}>{snackBarMessage.message}</Alert>
      </Snackbar>
      </div>
    </Modal>
  );
};
const AccountActionsPanel = ({ data, accounts }) => {
  const [createPostVisible, setCreatePostVisible] = React.useState(false);
  return (
    <Paper elevation={3}>
      <Box sx={{ p: 1 }}>
        <Typography component="h1" variant="h5" textAlign="left">
          Actions
        </Typography>
      </Box>
      <Box
        style={{ paddingTop: 20 }}
        sx={{
          display: "flex",
          flexWrap: "wrap",
          p: 1,
          m: 1,
          gap: 2,
          justifyContent: "flex-start",
        }}
      >
        <Button onClick={() => setCreatePostVisible(true)}>Create Post</Button>
      </Box>
      {createPostVisible && (
        <UploadModal
          open={createPostVisible}
          onClose={() => setCreatePostVisible(false)}
          accounts={accounts}
        />
      )}
    </Paper>
  );
};

export default AccountActionsPanel;
