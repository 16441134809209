import axios from 'axios';
import Cookies from "universal-cookie";
import { BASE_URL } from '../constants';
const cookies = new Cookies();

// Create axios client, pre-configured with baseURL
let APIKit = axios.create({
  baseURL: BASE_URL,
  timeout: 60000,
});
APIKit.interceptors.request.use(function(config) {
    let user = window.localStorage.getItem("sometracker-user-v2");
    try {
      user = JSON.parse(user);
    } catch(e) {

    }
    if(user && user !== 'null' && user.jwt)  {
      config.headers.Authorization = `Bearer ${user.jwt}`;
    }
    return config;
  });


export default APIKit;