import React, { useEffect, useState } from "react";
import {
  Button,
  Card,
  CardContent,
  CardMedia,
  CircularProgress,
  Fab,
  IconButton,
  Typography,
} from "@mui/material";
import CloudUploadIcon from "@mui/icons-material/CloudUpload";
import { Box } from "@mui/system";
import axios from "axios";
import CloseIcon from "@mui/icons-material/Close";
import APIKit from "../../services/api";
import { AP_URL, BASE_URL } from "../../constants";
import useAuth from "../../Context/auth";
const ImagePicker = ({onChange}) => {
  const [selectedImage, setSelectedImage] = useState(null);
  const [uploading, setUploading] = useState(null);
  const { user } = useAuth();

 const [uploadedImageUrl, setUploadedImageUrl] = useState(null);
  const handleImageUpload = async (event) => {
    setUploading(true);
    const file = event.target.files[0];
    const formData = new FormData();
    formData.append("file", file);

    try {
      console.log("Uploading", formData);
      const { data } = await APIKit.post(
        `${AP_URL}/marketing/upload`,
        formData,
        {
          headers: {
            "Content-Type": "multipart/form-data",
            Authorization: `Basic ${user.APD_TOKEN}`,
          },
        }
      );
      console.log("uploaded", data);
      setUploadedImageUrl(data.data.location);
    } catch (error) {
      console.log("Image upload error:", error);
      alert("Upload failed" + error.toString());
    }
    setUploading(false);
  };
  useEffect(() => {
    onChange && onChange(uploadedImageUrl);
  }, [uploadedImageUrl])
  return (
    <Card>
      <CardContent>
        {!uploadedImageUrl && <Box
          style={{ paddingTop: 20 }}
          sx={{
            display: "flex",
            flexWrap: "wrap",
            margin: '0 auto',
            width: '100%',
            p: 1,
            m: 1,
            gap: 2,
            justifyContent: "center",
          }}
        >
          {uploading && <CircularProgress />}
           {!uploading && <label htmlFor="image-picker">
            <Fab component="span">
              <CloudUploadIcon />
            </Fab>
          </label>}
        </Box>}
        {uploadedImageUrl && (
          <Box style={{ position: "relative", width: "408px", border: "4px dotted green", textAlign: 'center', margin: '0 auto', boxSizing: 'border-box' }}>
            <IconButton
              style={{
                position: "absolute",
                top: "5px",
                right: "5px",
              }}
              onClick={() =>setUploadedImageUrl(null)}
              color="primary"
              aria-label="Close"
            >
              <CloseIcon />
            </IconButton>
            <img
              src={uploadedImageUrl}
              style={{ height: "400px", width: "auto", textAlign: "center" }}
              alt="cap"
            />
          </Box>
        )}
        <input
          type="file"
          accept="image/*"
          style={{ display: "none" }}
          onChange={handleImageUpload}
          id="image-picker"
        />
        {/* <label htmlFor="image-picker">
          <Button variant="contained" component="span">
            Upload Image
          </Button>
        </label> */}
      </CardContent>
    </Card>
  );
};

export default ImagePicker;
