import React from "react";
import { Route, Redirect, Navigate } from "react-router-dom";
import Cookies from "universal-cookie";
import useAuth from "../Context/auth";
const cookies = new Cookies();

// receives component and any other props represented by ...rest
export default function AdminRoutes({ children, ...rest }) {
    const { user } = useAuth()
    console.log('user in admin check', user);
    if (user && user.role === 'admin') {
        return children
    } else {
        console.log('no admin')
        if(!user) {
            return (
            <Navigate
                //   state={{
                //     s:"ss"
                //   }}
                to={{
                    pathname: "/login",
                    state: {
                        // sets the location a user was about to assess before being redirected to login
                        from: "props.location",
                    },
                }}
            />
        );
         return (<p>Not user</p>)
        }
        return (
            <p>UnAuthorized</p>)
    }
}
