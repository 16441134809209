import axios from "axios";
import Cookies from "universal-cookie";
import { BASE_URL } from "../constants";

const cookies = new Cookies();

const register = (username, email, password) => {
  return axios.post(BASE_URL + "/signup", {
    username,
    email,
    password,
  });
};

const login = (username, password) => {
  return axios
    .get(BASE_URL + `/api/temp/login?username=${username}&password=${password}`)
    .then(({ data: response }) => {
        console.log('loginres', response)
      if (response.user) {
        // localStorage.setItem("user", JSON.stringify(response.data));
        //  cookies.set("TOKEN", response.data.token, {
        //   path: "/",
        // });

        return {
            success: true,
            user: response.user,
            jwt: response.jwt
        }
      }
      return {
        success: false,
        error: (response && response.error) || 'Failed to login'
      }
    }).catch(e => {
        return {
            success: false,
            error:  (e.response && e.response.data && e.response.data.error) || 'Failed to login'
        }
    });
};


const getCurrentUser = () => {
  return JSON.parse(localStorage.getItem("user"));
};

const AuthService = {
  register,
  login,
  getCurrentUser,
}

export default AuthService;